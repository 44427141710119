import useJwt from '@/auth/jwt/useJwt'

const nativeAppFcmLogin = () => {
  if (!window.ReactNativeWebView) {
    return null
  }
  window.ReactNativeWebView.postMessage(JSON.stringify({
    action: 'request_fcm_token',
  }))
  // You can use the userId to perform any necessary actions in your app,
  // such as saving the token to the database or setting up push notifications.
  window.saveNativeMobileAppToken = token => {
    localStorage.setItem('mobileFcmToken', token)
    useJwt.saveFcmToken({
      token,
    })
  }
  return null
}

const nativeAppSubscribeTopic = userId => {
  if (!window.ReactNativeWebView) {
    return null
  }
  window.ReactNativeWebView.postMessage(JSON.stringify({
    action: 'subscribe_fcm_topic',
    userId,
  }))
  return null
}

const unSubscribeTopic = userId => {
  if (!window.ReactNativeWebView) {
    return null
  }
  window.ReactNativeWebView.postMessage(JSON.stringify({
    action: 'unsubscribe_fcm_topic',
    userId,
  }))
  return null
}

export {
  nativeAppSubscribeTopic,
  nativeAppFcmLogin,
  unSubscribeTopic,
}
