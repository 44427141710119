import { getUserData } from '@/auth/utils'
import { USER_TYPE_STUDENT, USER_TYPE_TEACHER } from '@/const/userType'
import getLandingPageFor from '@/router/utils'
import useJwt from '@/auth/jwt/useJwt'
import moment from 'moment'
import i18n from '@/libs/i18n'
import GEC_EVENT_BUS from '@/utils/eventBus'
import { nativeAppFcmLogin, nativeAppSubscribeTopic } from './nativeAppFcmHandler'

export default function afterLogin(response, root) {
  const getCurrentStudentCharacter = () => {
    useJwt.getStudentCharacter().then(res => {
      if (res.data.data.character) {
        root.$store.commit('appConfig/UPDATE_SELECTED_CHARACTER', res.data.data.character.toLowerCase())
      }
    }).catch(error => {
      root.showErrorMessage(error)
    })
  }

  const userData = response.data.data
  useJwt.setToken(response.data.accessToken)
  localStorage.setItem('userData', JSON.stringify(userData))
  localStorage.setItem('locale', userData.language)
  i18n.locale = userData.language
  root.$store.commit('app/SET_COMPANY_INFORMATION')
  if (getUserData() && (getUserData().usertype === USER_TYPE_STUDENT || getUserData().usertype === USER_TYPE_TEACHER)) {
    root.$store.commit('appConfig/UPDATE_SCHOOL_INFO', userData.school || [])
    root.$store.commit('appConfig/UPDATE_DISTRICT_INFO', userData.district || [])
    root.$store.commit('appConfig/UPDATE_JUST_LOGGED_IN', true)
  }
  root.$router.replace({ name: getLandingPageFor(userData.usertype) }).then(() => {
    root.$store.dispatch('authEvents/onUserLogin')
    root.showSuccessMessage(response)
    if (getUserData() && getUserData().usertype === USER_TYPE_STUDENT) getCurrentStudentCharacter()
    root.$store.commit('appConfig/UPDATE_THEME_ON', userData.themeOn)
    if (parseInt(moment().diff(getUserData().dob, 'years'), 10) < 13) {
      root.$store.commit('appConfig/UPDATE_IS_STUDENT_YOUNG', true)
    }
    GEC_EVENT_BUS.$emit('loggedin')
    if (localStorage.locale !== 'en') {
      location.reload()
    }
  })
  nativeAppSubscribeTopic(userData.id)
  nativeAppFcmLogin()
  // save the mobile web application token here
}
